import request from "@/utils/request";
//验证码
export function sendCaptcha(data) {
  return request({
    url: "/business/send/sendCaptcha",
    method: "post",
    data
  });
}
//体检用户扫码后根据手机号和验证码登录并保存体检项目接口
export function loginAndSaveInspectionOrder(data) {
  return request({
    url: "/business/inspectionUser/loginAndSaveInspectionOrder",
    method: "post",
    data
  });
}
//体检用户公众号根据手机号和验证码登录
export function loginByTelPhoneAndCaptcha(data) {
  return request({
    url: "/business/inspectionUser/loginByTelPhoneAndCaptcha",
    method: "post",
    data
  });
}


//获取体检项目下拉列表接口
export function getInspectionItemList(data) {
  return request({
    url: "/business/inspectionItem/getInspectionItemList",
    method: "get",
    data
  });
}

//H5端获取检查报告列表接口
export function getInspectionReportList(data) {
  return request({
    url: "/business/inspectionOrder/getInspectionReportList",
    method: "post",
    data
  });
}

//获取用户openId接口
export function getOpenid(data) {
  return request({
    url: "/business/inspectionUser/getOpenid",
    method: "post",
    data
  });
}

