import Cookies from "js-cookie";
const Overtime = "overtime";
const RefreshToken = "refreshToken";
const Token = "token";
const UserId = "userId";
const Account = "account";
const UserType = "userType";

export function getOvertime() {
  return Cookies.get(Overtime);
}

export function setOvertime(overtime) {
  return Cookies.set(Overtime, overtime);
}

export function removeOvertime() {
  return Cookies.remove(Overtime);
}

// 获取token
export function getToken() {
  return Cookies.get(Token);
}
// 设置token
export function setToken(token) {
  return Cookies.set(Token, token);
}

export function removeToken() {
  return Cookies.remove(Token);
}

// 获取用户id
export function getUserId() {
  return Cookies.get(UserId);
}
// 设置用户id
export function setUserId(userId) {
  return Cookies.set(UserId, userId);
}

export function removeUserId() {
  return Cookies.remove(UserId);
}

// 获取Account
export function getAccount() {
  return Cookies.get(Account);
}
//设置Account
export function setAccount(account) {
  return Cookies.set(Account, account);
}

export function removeAccount() {
  return Cookies.remove(Account);
}

// 获取用户refreshToken
export function getRefreshToken() {
  return Cookies.get(RefreshToken);
}
// 设置用户refreshToken
export function setRefreshToken(refreshToken) {
  return Cookies.set(RefreshToken, refreshToken);
}

export function removeRefreshToken() {
  return Cookies.remove(RefreshToken);
}

// 获取用户userType
export function getUserType() {
  return Cookies.get(UserType);
}
// 设置用户userType
export function setUserType(userType) {
  return Cookies.set(UserType, userType);
}

export function removeUserType() {
  return Cookies.remove(UserType);
}
// 比较空字符
export function isEmptyStr(s) {
  if (s == undefined || s == null || s == "") {
    return true;
  }
  return false;
}
// 获取当前时间 比如 2023-02-21
export function getNowDate() {
  const timeOne = new Date();
  const year = timeOne.getFullYear();
  let month = timeOne.getMonth() + 1;
  let day = timeOne.getDate();
  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;
  const NOW_MONTHS_AGO = `${year}-${month}-${day}`;
  return NOW_MONTHS_AGO;
}

// 获取俩点间距离
export function getDistanceBetween(point1, point2) {
  // 计算像素距离
  const pixelDistance = Math.sqrt(
    Math.pow(point2.x - point1.x, 2) +
    Math.pow(point2.y - point1.y, 2)
  );
  return pixelDistance
}

// 两线之间夹角
export function getCalculateAngle(x1, y1, x2, y2) {
  const dx = x2 - x1;
  const dy = y2 - y1;
  return Math.atan2(dy, dx);
}
// 获取某点一段距离后点
export function getFindPointOnLine(x1, y1, angle, distance) {
  const newX = x1 + distance * Math.cos(angle);
  const newY = y1 + distance * Math.sin(angle);

  return { x: newX, y: newY };
}
//求两条线交点
export function getFindIntersection(x1, y1, x2, y2, x3, y3, x4, y4) {
  const denominator = (x4 - x3) * (y1 - y2) - (x1 - x2) * (y4 - y3);
  if (denominator === 0) {
    // 两条线平行，没有交点
    return null;
  } else {
    const t1 =
      ((x3 - x1) * (y4 - y3) - (y3 - y1) * (x4 - x3)) / denominator;
    // const t2 =
    //   -((x1 - x2) * (y3 - y1) - (y1 - y2) * (x3 - x1)) / denominator;

    // 计算交点坐标
    const x = x1 + t1 * (x2 - x1);
    const y = y1 + t1 * (y2 - y1);

    return { x, y };
  }
}
// 求两点中点
export function getFindMidpoint(x1, y1, x2, y2) {
  const midX = (x1 + x2) / 2;
  const midY = (y1 + y2) / 2;
  return { x: midX, y: midY };
}