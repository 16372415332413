<template>
  <div class="contentview">
    <div class="reporttitle">体检报告</div>
    <div
      class="itemContent"
      v-for="(item, index) in dataList"
      :key="index"
      @click="onClickItemMethod(item.reportPath)"
    >
      <div class="titleview">
        <span class="inspectiontitle">检线名称</span>
        <span class="title">{{ item.inspectionLineName }}</span>
      </div>
      <div class="titleview" style="display: flex; padding-bottom: 10px">
        <div class="inspectiontitle">检项名称</div>
        <div class="title" style="flex: 1">
          <div
            style="font-size: 15px; line-height: 17px; margin-bottom: 10px"
            v-for="(item, index) in item.inspectionItemS"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div style="border-top: 1px solid #e7e7e7; padding: 14px 0 4px 0">
        <span style="margin-right: 10px">报告时间</span>
        <span>{{ item.reportTime }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getInspectionReportList } from "@/api/login";
import { isEmptyStr, getUserId } from "@/utils/auth";

export default {
  data() {
    return {
      dataList: [],
    };
  },

  mounted() {
    getInspectionReportList({
      userId: getUserId(),
    }).then(
      (res) => {
        console.log(res);
        this.dataList = res.data;
      },
      (error) => {
        console.log(error);
      }
    );
  },

  methods: {
    onClickItemMethod(sender) {
      console.log(sender);
      this.$router.push({
        path: "./pdf",
        query: { url: sender },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contentview {
  height: calc(100vh - 20px);
  overflow-y: scroll;
  .reporttitle {
    margin: 10px 0 0 12px;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Semibold;
    font-weight: 600;
    text-align: left;
    color: #262626;
    line-height: 18px;
  }
  .itemContent {
    margin: 10px 10px 0 10px;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    font-size: 15px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 500;
    color: #999999;
    line-height: 17px;
    .titleview {
      margin-top: 10px;
    }
    .inspectiontitle {
      margin-right: 10px;
      font-size: 15px;
      line-height: 17px;
    }
    .title {
      color: #262626;
    }
  }
}
</style>