<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@import url(@/assets/css/common.css);

#app {
  font-family: PingFang SC, PingFang SC-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #262626;
  background: #f6f6fe;
}
* {
  font-family: PingFang SC, PingFang SC-Regular;
}
</style>
