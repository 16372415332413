import store from "@/store";
import axios from "axios";
import { ElMessage, ElMessageBox } from 'element-plus'

import hex_md5 from "js-md5";
import {
  getToken,
  getRefreshToken,
} from "@/utils/auth";


let Base64 = require("js-base64").Base64;

let baseURL = PLATFROM_CONFIG.VUE_APP_BASE_API;

const service = axios.create({
  baseURL,
  timeout: 90000, // request timeout
});
service.interceptors.request.use(
  (config) => {
    if (getToken()) {
      config.headers["Authorization"] = getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    config.headers.firmId = PLATFROM_CONFIG.VUE_APP_BASE_FIRMID;
    if (getRefreshToken()) {
      config.headers.refreshToken = getRefreshToken(); // 刷新tekon
    }
    config.headers.Language = localStorage.getItem("lang") || "CN";
    sign(config);
    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(

  (response) => {
    console.log(response);
    const res = response.data;
    if (res.success == "1" || res.code == 20000 || res.code == 20013) {
      return res;
    } else if (res.success == "9" || res.code == 20006) {

      // ElMessageBox.alert('This is a message', 'Title', {
      //   // if you want to disable its autofocus
      //   // autofocus: false,
      //   confirmButtonText: 'OK',
      //   callback: (action: Action) => {
      //     ElMessage({
      //       type: 'info',
      //       message: `action: ${action}`,
      //     })
      //   },
      // })

    } else {
      ElMessage({
        message: res.message || "Error",
        type: "error",
        plain: true,
      });
      return Promise.reject(res.message || "Error");
    }
  },
  (error) => {

    store.dispatch("LogOut").then(() => {
      location.reload();
    });
    return Promise.reject(error);
  }
);

export default service;

//进行签名
function sign(config) {
  let json = "{}";
  if (config.method == "post") {
    if (config.data) {
      json = JSON.stringify(config.data);
    }
    var base64 = Base64.encode(json);
    let data = {
      data: base64
    };
    data.timestamp = new Date().getTime() + "";
    data.nonce = guid();
    data.token = config.headers.Authorization;
    let s = "";
    Object.keys(data)
      .sort()
      .forEach((k) => {
        if (data[k] && data[k].length > 0) {
          if (s.length > 0) {
            s += "&";
          }
          s += k + "=" + data[k];
        }
      });
    let md5 = hex_md5(s);
    data.sign = md5;
    config.data = data;
  }
}

function guid() {
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
}