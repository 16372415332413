<template>
  <div class="contentview">
    <img src="@/assets/back.png" alt="" class="pdf-img" @click="onBackMethod" />
    <div v-for="pageNum in totalPages" :key="pageNum" class="pdf-viewer">
      <canvas
        ref="pdfCanvas"
        :id="`pdf-canvas-${pageNum}`"
        style="max-width: 100%"
      ></canvas>
    </div>
  </div>
</template>

<script>
import * as pdfjsLib from "pdfjs-dist";
import * as pdfjs from "pdfjs-dist/build/pdf.worker.js";

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjs;
window.pdfjsWorker = pdfjs;
export default {
  data() {
    return {
      pdfDocument: null,
      totalPages: 0,
      pdfUrl: "",
    };
  },
  methods: {
    onBackMethod() {
      this.$router.go(-1);
    },
  },

  async mounted() {
    // var url = window.location.href;

    // this.pdfUrl = url.substring(url.indexOf("?") + 1);

    this.pdfUrl = this.$route.query.url;
    // this.pdfUrl = "http://192.168.21.17:8080/528-1792403120177-DR.pdf";

    try {
      // Load the PDF document
      this.pdfDocument = await pdfjsLib.getDocument({
        url: this.pdfUrl,
        cMapUrl: "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.943/cmaps/",
        cMapPacked: true,
      }).promise;
      this.totalPages = this.pdfDocument.numPages;
      // Render each page using the canvas
      for (let pageNum = 1; pageNum <= this.totalPages; pageNum++) {
        const page = await this.pdfDocument.getPage(pageNum);
        const canvas = document.getElementById(`pdf-canvas-${pageNum}`);
        const context = canvas.getContext("2d");
        const viewport = page.getViewport({ scale: 1 });
        canvas.height = viewport.viewBox.length > 3 ? viewport.viewBox[3] : 842;
        canvas.width = viewport.viewBox.length > 2 ? viewport.viewBox[2] : 595;
        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };
        renderContext.canvasContext.scale(1, -1);
        renderContext.canvasContext.translate(0, -canvas.height);
        await page.render(renderContext).promise;
      }
    } catch (error) {
      console.error("Error loading PDF:", error);
    }
  },
};
</script>

<style lang='scss' scoped>
.contentview {
  position: relative;
  // width: 100%;
  // height: 100%;
  overflow: hidden;

  .pdf-img {
    position: fixed;
    left: 5%;
    top: 3%;
  }
  // .pdf-viewer {
  //   text-align: center;
  //   max-width: 100%;
  // }
}
</style>