<template>
  <div class="successview">
    <div class="headview">
      <img src="@/assets/success.png" alt="" srcset="" />
      <div>提交成功</div>
    </div>
    <!-- <div class="footview">
      <p style="white-space: pre-line">
        {{ `请在【天津长木谷综合门诊部互联网医院】\n公众号查收报告通知消息` }}
      </p>
    </div> -->
    <div class="footview">
      <img src="@/assets/qrcode.png" alt="" srcset="" />
      <p>关注【天津长木谷综合门诊部互联网医院】公众号</p>
      <p class="reporttext">》以便接收报告《</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>
<style lang="scss" scoped>
.successview {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .headview {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 600;
    text-align: center;
    color: #262626;
    line-height: 25px;
    img {
      margin-top: 90px;
      width: 120px;
      margin-bottom: 16px;
    }
  }
  .footview {
    margin-bottom: 160px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 500;
    text-align: center;
    color: #262626;
    line-height: 21px;
    img {
      width: 159px;
      margin-bottom: 20px;
    }
    .reporttext {
      color: #ff1515;
    }
  }
}
</style>