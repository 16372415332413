import { createRouter, createWebHistory } from 'vue-router';
// import Login from '@/views/login/index.vue';
import Home from "@/views/home/index.vue";
import Success from "@/views/success/index.vue";
import PdfContent from "@/views/home/pdfContent.vue"
const Login = () => import("@/views/login/index.vue")

// 创建路由实例
const router = createRouter({
  history: createWebHistory("/"),
  routes: [
    {
      path: '/',
      component: Login,
      meta: { title: '登录' }
    },
    {
      path: '/home',
      component: Home,
      meta: { title: '骨力宝' }
    },
    {
      path: '/success',
      component: Success,
      meta: { title: '关注公众号' }
    },
    {
      path: '/pdf',
      component: PdfContent,
      meta: { title: '报告' }
    }
  ]
  // routes: [
  //   {
  //     path: '/',
  //     component: PdfContent,
  //     meta: { title: '报告' }
  //   }
  // ]
});

// 全局前置守卫
router.beforeEach((to, from, next) => {
  // 设置页面标题
  document.title = to.meta.title || '骨力宝'
  next()
})


export default router;
