import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import "@/utils/px2rem"
import 'element-plus/dist/index.css'

import ElementPlus from 'element-plus'


const app = createApp(App);

app.use(router);
app.use(store);
app.use(ElementPlus)

app.mount('#app');
